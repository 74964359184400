import React from 'react';

function LetterWheel({ currentLetter }) {
  return (
    <div className="LetterWheel">
      <div className="current-letter-box">
        <h1 className="current-letter">{currentLetter || '\u00A0'}</h1> {/* Unicode non-breaking space to keep the box height */}
      </div>
    </div>
  );
}

export default LetterWheel;
