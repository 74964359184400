import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './components/Homepage';
import Impressum from './components/Impressum';
import ScrollToTop from './components/ScrollToTop';

function App() {
    return (

        <div>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/impressum" element={<Impressum />} />
            </Routes>
        </div>
    );
}

export default App;

