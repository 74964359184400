import React from 'react';

function ControlPanel({ onReset, setTime }) {
  const handleTimeChange = (e) => {
    setTime(Number(e.target.value));
  };

  return (
    <div className="ControlPanel">
      <div className="row">
        <label>
          Zeit ändern (Sekunden):
          <input type="number" onChange={handleTimeChange} defaultValue={120} className="time-input"/>
        </label>
      </div>
      {/*
      <div className="row">
        <button onClick={onReset}>Reset</button>
      </div> */}
    </div>
  );
}

export default ControlPanel;
