import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';

const Timer = forwardRef(({ time, gongRef, countdownSoundRef, onTimerEnd }, ref) => {
  const [seconds, setSeconds] = useState(time);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useImperativeHandle(ref, () => ({
    startTimer() {
      setSeconds(time);
      setIsActive(true);
      setIsPaused(false);
    },
    resetTimer() {
      setSeconds(time);
      setIsActive(false);
      setIsPaused(false);
    },
    pauseTimer() {
      setIsPaused(true);
    },
    resumeTimer() {
      setIsPaused(false);
      setIsActive(true); // Ensure the timer restarts
    }
  }));

  useEffect(() => {
    let timerId;
    if (isActive && !isPaused) {
      if (seconds > 0) {
        if (seconds === 3) {
          countdownSoundRef.current.play();
        }
        timerId = setInterval(() => setSeconds(prevSeconds => prevSeconds - 1), 1000);
      } else {
        gongRef.current.play();
        setIsActive(false);
        setTimeout(() => {
          ref.current.resetTimer();
          if (onTimerEnd) {
            onTimerEnd();
          }
        }, 2000); // Reset the timer 2 seconds after it reaches 0
      }
    }
    return () => clearInterval(timerId);
  }, [isActive, isPaused, seconds, ref, gongRef, countdownSoundRef, onTimerEnd]);

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
    <div className="Timer">
      <h2 className="timer-display">{formatTime(seconds)}</h2>
    </div>
  );
});

export default Timer;
