import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import '../App.css';
import LetterWheel from './LetterWheel';
import Timer from './Timer';
import ControlPanel from './ControlPanel';
import Modal from './Modal';
import NoSleep from 'nosleep.js';
import playIcon from '../assets/play.png'; // Add the path to your play icon
import pauseIcon from '../assets/pause.png'; // Add the path to your pause icon
import sound_start from '../assets/whistle.wav';
import sound_countdown from '../assets/countdown.wav';
import sound_end from '../assets/bell.wav';
import logo from '../assets/logo.png'; // Add the path to your logo


function HomePage() {
  const [currentLetter, setCurrentLetter] = useState(null);
  const [usedLetters, setUsedLetters] = useState([]);
  const [time, setTime] = useState(120);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [isNoSleep, setIsNoSleep] = useState(false);
  const noSleep = useRef(new NoSleep());
  const timerRef = useRef(null);
  const gongRef = useRef(new Audio(sound_end));
  const startSoundRef = useRef(new Audio(sound_start));
  const countdownSoundRef = useRef(new Audio(sound_countdown));

  useEffect(() => {
    const synth = window.speechSynthesis;
    const loadVoices = () => {
      const allVoices = synth.getVoices();
      const stefanVoice = allVoices.find(voice => voice.name.includes('Stefan'));
      if (stefanVoice) {
        setSelectedVoice(stefanVoice);
      }
    };

    synth.onvoiceschanged = loadVoices;
    loadVoices();
  }, []);

  useEffect(() => {
    // Preload the audio files on user interaction
    const handleUserInteraction = () => {
      gongRef.current.load();
      startSoundRef.current.load();
      countdownSoundRef.current.load();
      document.removeEventListener('click', handleUserInteraction);
    };
    document.addEventListener('click', handleUserInteraction);
  }, []);

  const handleStart = () => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    const availableLetters = letters.filter(letter => !usedLetters.includes(letter));
    if (availableLetters.length === 0) {
      setShowModal(true);
      return;
    }
    const randomLetter = availableLetters[Math.floor(Math.random() * availableLetters.length)];
    setCurrentLetter(randomLetter);
    setUsedLetters([...usedLetters, randomLetter]);

    const utterance = new SpeechSynthesisUtterance(randomLetter.toLowerCase());
    utterance.rate = 0.5; // Slower speech rate
    if (selectedVoice) {
      utterance.voice = selectedVoice;
    }
    window.speechSynthesis.speak(utterance);

    setTimeout(() => {
      startSoundRef.current.play();
      timerRef.current.startTimer();
    }, 2000); // 2 seconds delay
  };

  const handleReset = () => {
    setCurrentLetter(null);
    setUsedLetters([]);
    timerRef.current.resetTimer();
  };

  const handlePause = () => {
    setIsPaused(prevState => {
      if (prevState) {
        timerRef.current.resumeTimer();
      } else {
        timerRef.current.pauseTimer();
      }
      return !prevState;
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    timerRef.current.resumeTimer();
  };

  const handleToggleNoSleep = () => {
    setIsNoSleep(prevState => {
      if (prevState) {
        noSleep.current.disable();
      } else {
        noSleep.current.enable();
      }
      return !prevState;
    });
  };

  const handleTimerEnd = () => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    if (usedLetters.length === letters.length) {
      setShowModal(true);
      timerRef.current.pauseTimer(); // Pause the timer when modal is shown
    }
  };

  const toggleControlPanel = () => {
    setShowControlPanel(prevState => !prevState);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Logo" className="App-logo" />
      </header>
      <main>
      <div className="controls">
        <button className="start" onClick={handleStart}>START</button>
        <button className="pause" onClick={handlePause} disabled={!currentLetter}>
          <img src={isPaused ? playIcon : pauseIcon} alt="Play/Pause" className="icon"/>
        </button>
      </div>
      <div className="toggle-container">
        <label className="toggle-label">
          <input type="checkbox" onChange={handleToggleNoSleep} checked={isNoSleep} />
          <span className="slider"></span>
        </label>
        <span className="toggle-text">Game Modus</span>
        <div className="tooltip-container">
          <span className="info-icon">i</span>
          <div className="tooltip-text">Die Bildschirmsperre wird ignoriert</div>
        </div>
      </div>
      <LetterWheel currentLetter={currentLetter} />
      <div className="timer-container" onClick={toggleControlPanel}>
        <Timer ref={timerRef} time={time} gongRef={gongRef} countdownSoundRef={countdownSoundRef} onTimerEnd={handleTimerEnd} />
      </div>
      {usedLetters.length > 0 && (
        <div className="usedLetters-board">
          {usedLetters.map((letter, index) => (
            <span key={index} className="used-letter">{letter}</span>
          ))}
        </div>
      )}
      {showControlPanel && (
        <ControlPanel
          onReset={handleReset}
          setTime={setTime}
        />
      )}
      <div>
      <button className="reset" onClick={handleReset}>RESET</button>
      <Modal show={showModal} handleClose={handleCloseModal} />
      </div>
      </main>
      <footer className="App-footer">
      <p className="Link"><Link to="/impressum"> Impressum </Link></p>
      </footer>
    </div>
  );
}

export default HomePage;
