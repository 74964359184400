import React from 'react';
import { Link } from "react-router-dom";
import '../App.css';
import '../Impressum.css';
import logo from '../assets/logo.png';


const Impressum = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Logo" className="App-logo" />
      </header>
      <main className="impressum">
        <h2>Impressum</h2>
        <p>Angaben gemäß  §5 TMG. Kontakt- und Adressdaten siehe nachfolgend:</p>
        <ul className="list-imp">
          <li>Marvin Dilg</li>
          <li>c/o IP-Management #38371</li>
          <li>Ludwig-Erhard-Str. 18</li>
          <li>20459 Hamburg</li>
          <li>E-Mail: mave7qq@gmail.com</li>
          <li>Handynummer: +49 174 6659006</li>
        </ul>
        <h2>Haftungsausschluss</h2>
        <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.</p>

        <h2>Datenschutz</h2>

        <h3>Einleitung</h3>
        <p>Der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzrichtlinie.</p>

        <h3>Verantwortlicher</h3>
        <p>Verantwortlich für die Datenverarbeitung auf dieser Website ist Marvin Dilg. Kontaktdaten siehe Impressum.</p>

        <h3>Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h3>

        <h3>Besuch der Website</h3>
        <p>Beim Aufrufen unserer Website werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sogenannten Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:</p>
        <ul className="list">
          <li>IP-Adresse des anfragenden Rechners</li>
          <li>Datum und Uhrzeit des Zugriffs</li>
          <li>Name und URL der abgerufenen Datei</li>
          <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
          <li>Verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers</li>
        </ul>
        <p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</p>
        <ul className="list">
          <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website</li>
          <li>Gewährleistung einer komfortablen Nutzung unserer Website</li>
          <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
          <li>zu weiteren administrativen Zwecken</li>
        </ul>
        <p>Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.</p>

        <h3>Verwendung von Cookies</h3>
        <p>Unsere Website verwendet nur notwendige Cookies. Dies sind kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen. In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.</p>
        <p>Der Einsatz von Cookies dient dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. Diese Cookies sind notwendig, um die grundlegenden Funktionen der Website sicherzustellen. Rechtsgrundlage für die Nutzung dieser notwendigen Cookies ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>

        <h3>Weitergabe von Daten</h3>
        <p>Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.</p>
        <p>Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>
        <ul className="list">
          <li>Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben</li>
          <li>die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben</li>
          <li>für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie</li>
          <li>dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist</li>
        </ul>

        <h3>Betroffenenrechte</h3>
        <p>Sie haben das Recht:</p>
        <ul className="list">
          <li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen</li>
          <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen</li>
          <li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen</li>
          <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen</li>
          <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen</li>
          <li>gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen</li>
          <li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren</li>
        </ul>

        <h3>Widerspruchsrecht</h3>
        <p>Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben.</p>

        <h3>Datensicherheit</h3>
        <p>Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256-Bit-Verschlüsselung. Falls Ihr Browser keine 256-Bit-Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit-v3-Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.</p>

        <h3>Aktualität und Änderung dieser Datenschutzrichtlinie</h3>
        <p>Diese Datenschutzrichtlinie ist aktuell gültig und hat den Stand Juli 2024.</p>
        <p>Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzrichtlinie zu ändern.</p>

        <h3>Impressum-Privatschutz (IP-Management)</h3>
        <p>Wir verwenden für die Verwaltung der an uns gesendeten Post die Dienstleistungen der IMPRESSUMPRIVATSCHUTZ GmbH, Ludwig-Erhard-Str. 18, 20459 Hamburg. Dieser Dienstleister bietet eine sichere
          und zuverl‰ssige Postanschrift für unser Projekt (z.B. Impressum, Datenschutzerkl‰rung,
          Widerrufsbelehrung und ggf. weitere Bereiche). Dies stellt auch unser berechtigtes Interesse im Sinne
          des Art. 6 Abs. 1 lit. f DSGVO dar. Wir haben mit Impressum-Privatschutz einen Vertrag zur
          Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen
          Datenschutzbehörden bei der Nutzung dieser Dienstleistung vollst‰ndig um. Weitere Informationen
          über Datenschutz bei Impressum-Privatschutz GmbH finden Sie hier: https://impressumprivatschutz.de/datenschutzerklaerung/</p>


      </main>
      <footer className="App-footer">
        <p className="Link"><Link to="/"> Zurück </Link></p>
      </footer>
    </div>
  );
};

export default Impressum;
